import React from "react";

export default function Machine() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="title text-center">
            <h2>The machine</h2>

            <div className="border-meghna"></div>
          </div>
        </div>
      </div>
      <h2>Commercial low temperature MOCVD system for 2D semiconductors</h2>
      <div className="row">
        <div className="col-lg-6">
          <img
            className="img-fluid lozad"
            alt="image"
            src="images/2D/machine.png"
            data-loaded="true"
            style={{
              maxHeight: "500px",
            }}
          />
        </div>

        <div className="col-lg-6 text-left" style={{ display: "flex", alignItems: "center" }}>
          <ul className="skill-bar">
            <li>
              <p>
                <span>• </span> Growth temperature down to 350°C
              </p>
            </li>
            <li>
              <p>
                <span>• </span> On any substrate with any lattice constant
              </p>
            </li>
            <li>
              <p>
                <span>• </span> 60 seconds to achieve full coverage
              </p>
            </li>
            <li>
              <p>
                <span>• </span> In-situ defect removal
              </p>
            </li>
            <li>
              <p>
                <span>• </span> Production-ready 8" cold wall system
              </p>
            </li>
            <li>
              <p>
                <span>• </span> 12" system available on request
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
