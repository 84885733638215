import React, { useState } from "react";
import Parse from "parse/dist/parse.min.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Order() {
  const [form, setForm] = useState({ name: "", email: "", budget: "", organization: "", specifications: "" });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const Order = Parse.Object.extend("Order");
    const newOrder = new Order();

    newOrder.set("name", form.name);
    newOrder.set("email", form.email);
    newOrder.set("budget", form.budget);
    newOrder.set("organization", form.organization);
    newOrder.set("specifications", form.specifications);

    try {
      await newOrder.save();
      // Handle successful submission
      toast.success("Submit successful");
      // sendgridEmail("x@phantafield.com", "Subject of the Email", "Body of the Email");
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className="container">
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
      />{" "}
      <div className="row">
        <div className="col-lg-12">
          <div className="title text-center">
            <h2>Order now</h2>
            <div className="border-meghna"></div>
          </div>
        </div>
      </div>
      <div className="rol justify-content-center d-flex">
        <div className="col-lg-6 wow fadeInUp" data-wow-duration="500ms" data-wow-delay="300ms">
          <form id="contact-form" className="form-meghna" method="post" action="#" role="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Your Name"
                className="form-control"
                name="name"
                id="name"
                value={form.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Your Email"
                className="form-control"
                name="email"
                id="email"
                value={form.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                rows="6"
                placeholder="Your Organization"
                className="form-control"
                name="organization"
                id="organization"
                value={form.organization}
                onChange={handleChange}
              ></input>
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Your Budget"
                className="form-control"
                name="budget"
                id="budget"
                value={form.budget}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <textarea
                rows="6"
                placeholder="Tell us about yourself (Due to the limited production capability, and large demand, we can only serve the customer with strategically important project.)"
                className="form-control"
                name="specifications"
                id="specifications"
                value={form.specifications}
                onChange={handleChange}
              ></textarea>
            </div>

            <div id="cf-submit">
              <button
                type="submit"
                id="contact-submit"
                className="btn btn-transparent btn-block"
                value="Submit"
                style={{ backgroundColor: "black" }}
              >
                Get on the waitlist
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
