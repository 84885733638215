import React from "react";

export default function Material() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="title text-center wow fadeIn" data-wow-duration="1500ms">
            <h2> Material</h2>
            <div className="border-meghna"></div>
          </div>
        </div>
        <div className="col-lg-4 text-center wow fadeInUp" data-wow-duration="500ms">
          <div className="block">
            <div className="">
              <i className="ti-ruler-pencil">
                <img src="images/2D/2d.gif" alt="2D" height={200} />
              </i>
            </div>
            <div className="content text-center">
              <h3>Atomically thin</h3>
              <p>
                2D semiconductor materials are the world's thinnest, with a thickness of just 3 atoms. These properties
                make them perfect for creating the smallest transistors. Additionally, their flexibility and
                transparency make them also an ideal choice for wearable applications.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 text-center wow fadeInUp" data-wow-duration="500ms">
          <div className="block">
            <div className="">
              <i className="ti-cup">
                <img src="images/2D/monolithic.png" alt="2D" height={200} />
              </i>
            </div>
            <div className="content text-center">
              <h3>Monolithic 3D</h3>
              <p>
                Transistors made from thin films of 2D materials can be stacked directly on top of each other or any
                substrate, eliminating the need for wafer bonding. Instead of using through-silicon vias (TSVs), these
                transistors utilize high-density interconnect between layers, providing an exceptional level of
                interlayer communication bandwidth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
